import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyBPk6HOfTg0JTjUeMVwSQeta6-7C2a-UuY",
  authDomain: "football-pool-v2.firebaseapp.com",
  databaseURL: "https://football-pool-v2-default-rtdb.firebaseio.com",
  projectId: "football-pool-v2",
  storageBucket: "football-pool-v2.appspot.com",
  messagingSenderId: "1028875405643",
  appId: "1:1028875405643:web:ec72b4ef9c45800cbc80ce",
  measurementId: "G-XW3T2GEYKF"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore();

